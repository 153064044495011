import { BuyCycle, ListTableStock, StockIndicators } from '../../types'

export function HealthScore({ record }: { record: StockIndicators & ListTableStock; title: string }) {
  const { healthScore } = record

  if (healthScore !== undefined && healthScore !== null) {
    if (healthScore === 6) {
      return <div style={{ color: 'green' }}>VERY HEALTHY - {healthScore}</div>
    } else if (healthScore >= 5) {
      return <div style={{ color: 'lightgreen' }}>HEALTHY - {healthScore}</div>
    } else if (healthScore >= 4) {
      return <div style={{ color: 'gold' }}>OK - {healthScore}</div>
    } else if (healthScore > 2) {
      return <div style={{ color: 'orange' }}>UNHEALTHY - {healthScore}</div>
    } else {
      return <div style={{ color: 'red' }}>SICK - {healthScore}</div>
    }
  }
  return <div>Unknown</div>
}

export function BuyScore({ record }: { record: StockIndicators & ListTableStock; title: string }) {
  const { buyScore, healthScore, buyCycle = BuyCycle.UNKNOWN } = record

  // useEffect(() => {
  //   if (!buyScore) {
  //     getSwingTrades([T])
  //   }
  // }, [])
  // let score = 0
  // if (record.rising200) score += 1
  // if (record.rising100) score += 1
  // if (record.rising20) score += 1
  // if (record.nineRising) score += 2
  // if (record.yield) {
  //   if (record.yield > 0.5) score += 1
  //   if (record.yield > 0.1) score += 1
  // }
  // if (title === 'High Risk') {
  //   score -= 1
  // }
  // if (record.T === 'YMAX') {
  //   console.log('YMAX', record, score)
  // }

  if (buyScore !== undefined && buyScore !== null) {
    if (buyScore >= 5) {
      return <div style={{ color: 'green' }}>STRONG BUY - {buyCycle}</div>
    } else if (buyScore >= 4) {
      return <div style={{ color: 'lightgreen' }}>BUY - {buyCycle}</div>
    } else if (buyScore >= 3) {
      return <div style={{ color: 'gold' }}>BUY/WATCH - {buyCycle}</div>
    } else if (buyScore >= 2) {
      return <div style={{ color: 'orange' }}>HOLD/WATCH - {buyCycle}</div>
    } else {
      return <div style={{ color: 'red' }}>BAD BUY - {buyCycle}</div>
    }
  }
  return <div>...</div>
}
