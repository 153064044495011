import { useStore } from '../store/basic-store'
import { Card, Col, Layout, Row, Table, TableColumnsType } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { useEffect, useState } from 'react'
import { getDList, getDListStatus, getSwingTrades, getTickerTypes } from '../service/dlist.action'
import { ListTableStock, Stock, StockIndicators, TickerTypeCode, TickerTypes } from '../types/stock.types'
import { User } from '../types'
import { BackHome } from '../components/back-home'
import { useNavigate } from 'react-router-dom'
import { BuyScore, HealthScore } from '../components/dlist/health'

const divDates = [
  { group: 'D', decDate: '2025-01-15', exDate: '2025-01-16', payDate: '2025-01-17' },
  { group: 'A', decDate: '2025-01-22', exDate: '2025-01-23', payDate: '2025-01-24' },
  { group: 'B', decDate: '2025-01-29', exDate: '2025-01-30', payDate: '2025-01-31' },
  { group: 'C', decDate: '2025-02-05', exDate: '2025-02-06', payDate: '2025-02-07' },
  { group: 'D', decDate: '2025-02-12', exDate: '2025-02-13', payDate: '2025-02-14' },
  { group: 'A', decDate: '2025-02-19', exDate: '2025-02-20', payDate: '2025-02-21' },
  { group: 'B', decDate: '2025-02-26', exDate: '2025-02-27', payDate: '2025-02-28' },
  { group: 'C', decDate: '2025-03-05', exDate: '2025-03-06', payDate: '2025-03-07' },
  { group: 'D', decDate: '2025-03-12', exDate: '2025-03-13', payDate: '2025-03-14' },
  { group: 'A', decDate: '2025-03-19', exDate: '2025-03-20', payDate: '2025-03-21' },
  { group: 'B', decDate: '2025-03-26', exDate: '2025-03-27', payDate: '2025-03-28' },
  { group: 'C', decDate: '2025-04-02', exDate: '2025-04-03', payDate: '2025-04-04' },
  { group: 'D', decDate: '2025-04-09', exDate: '2025-04-10', payDate: '2025-04-11' },
  { group: 'A', decDate: '2025-04-16', exDate: '2025-04-17', payDate: '2025-04-21' },
  { group: 'B', decDate: '2025-04-23', exDate: '2025-04-24', payDate: '2025-04-25' },
  { group: 'C', decDate: '2025-04-30', exDate: '2025-05-01', payDate: '2025-05-02' },
  { group: 'D', decDate: '2025-05-07', exDate: '2025-05-08', payDate: '2025-05-09' },
  { group: 'A', decDate: '2025-05-14', exDate: '2025-05-15', payDate: '2025-05-16' },
  { group: 'B', decDate: '2025-05-21', exDate: '2025-05-22', payDate: '2025-05-23' },
  { group: 'C', decDate: '2025-05-28', exDate: '2025-05-29', payDate: '2025-05-30' },
  { group: 'D', decDate: '2025-06-04', exDate: '2025-06-05', payDate: '2025-06-06' },
  { group: 'A', decDate: '2025-06-11', exDate: '2025-06-12', payDate: '2025-06-13' },
  { group: 'B', decDate: '2025-06-18', exDate: '2025-06-20', payDate: '2025-06-23' },
  { group: 'C', decDate: '2025-06-25', exDate: '2025-06-26', payDate: '2025-06-27' },
  { group: 'D', decDate: '2025-07-02', exDate: '2025-07-03', payDate: '2025-07-07' },
  { group: 'A', decDate: '2025-07-09', exDate: '2025-07-10', payDate: '2025-07-11' },
  { group: 'B', decDate: '2025-07-16', exDate: '2025-07-17', payDate: '2025-07-18' },
  { group: 'C', decDate: '2025-07-23', exDate: '2025-07-24', payDate: '2025-07-25' },
  { group: 'D', decDate: '2025-07-30', exDate: '2025-07-31', payDate: '2025-08-01' },
  { group: 'A', decDate: '2025-08-06', exDate: '2025-08-07', payDate: '2025-08-08' },
  { group: 'B', decDate: '2025-08-13', exDate: '2025-08-14', payDate: '2025-08-15' },
  { group: 'C', decDate: '2025-08-20', exDate: '2025-08-21', payDate: '2025-08-22' },
  { group: 'D', decDate: '2025-08-27', exDate: '2025-08-28', payDate: '2025-08-29' },
  { group: 'A', decDate: '2025-09-03', exDate: '2025-09-04', payDate: '2025-09-05' },
  { group: 'B', decDate: '2025-09-10', exDate: '2025-09-11', payDate: '2025-09-12' },
  { group: 'C', decDate: '2025-09-17', exDate: '2025-09-18', payDate: '2025-09-19' },
  { group: 'D', decDate: '2025-09-24', exDate: '2025-09-25', payDate: '2025-09-26' },
  { group: 'A', decDate: '2025-10-01', exDate: '2025-10-02', payDate: '2025-10-03' },
  { group: 'B', decDate: '2025-10-08', exDate: '2025-10-09', payDate: '2025-10-10' },
  { group: 'C', decDate: '2025-10-15', exDate: '2025-10-16', payDate: '2025-10-17' },
  { group: 'D', decDate: '2025-10-22', exDate: '2025-10-23', payDate: '2025-10-24' },
  { group: 'A', decDate: '2025-10-29', exDate: '2025-10-30', payDate: '2025-10-31' },
  { group: 'B', decDate: '2025-11-05', exDate: '2025-11-06', payDate: '2025-11-07' },
  { group: 'C', decDate: '2025-11-12', exDate: '2025-11-13', payDate: '2025-11-14' },
  { group: 'D', decDate: '2025-11-19', exDate: '2025-11-20', payDate: '2025-11-21' },
  { group: 'A', decDate: '2025-11-26', exDate: '2025-11-28', payDate: '2025-12-01' },
  { group: 'B', decDate: '2025-12-03', exDate: '2025-12-04', payDate: '2025-12-05' },
  { group: 'C', decDate: '2025-12-10', exDate: '2025-12-11', payDate: '2025-12-12' },
  { group: 'D', decDate: '2025-12-17', exDate: '2025-12-18', payDate: '2025-12-19' },
  { group: 'A', decDate: '2025-12-24', exDate: '2025-12-26', payDate: '2025-12-29' },
]

function getGroup(T: string) {
  let group = T === 'YMAX' || T === 'YMAG' ? 'ALL' : ''
  if (groupA.includes(T)) group = 'A'
  if (groupB.includes(T)) group = 'B'
  if (groupC.includes(T)) group = 'C'
  if (groupD.includes(T)) group = 'D'
  return group
}

function findNextDivDate(group: string) {
  const now = Date.now()
  const dates = group === 'ALL' ? divDates : divDates.filter((d) => d.group === group)
  if (!dates.length) return ''
  for (let i = 0; i < dates.length; i++) {
    const date = dates[i]
    const ex = new Date(date.exDate).getTime()
    if (now < ex) return date.exDate
  }
  return ''
}
const troubled = [
  'CMCT',
  'FF',
  'FBIOP',
  'SVC',
  'CLCO',
  'PFIX',
  'SACH',
  'OPI',
  'QRTEP',
  'TRMD',
  'RILYP',
  'GREEL',
  'RILY',
  'NEP',
  'MVO',
  'GIPR',
  'KLIP',
]
const groupA = ['TSLY', 'CRSH', 'GOOY', 'YBIT', 'OARK', 'XOMO', 'SNOY', 'TSMY']
const groupB = ['NVDY', 'DIPS', 'FBY', 'GDXY', 'BABO', 'JPMO', 'MRMY', 'PLTY', 'MARO', 'MRNY']
const groupC = ['CONY', 'FIAT', 'MSFO', 'AMDY', 'NFLY', 'ABNY', 'PYPY', 'ULTY']
const groupD = ['MSTY', 'YQQQ', 'AMZY', 'APLY', 'AIYY', 'DISO', 'SQY', 'SMCY']
const weekly = ['YMAX', 'YMAG', 'LFGY', 'GPTY', 'SDTY']
const oneDay = 24 * 60 * 60 * 1000
const owned = [
  { T: 'IEP', shares: 30 },
  { T: 'ORC', shares: 24.3677 },
  { T: 'PSEC', shares: 11 },
  { T: 'CHMI', shares: 1 },
  { T: 'YMAX', shares: 711.97 },
  { T: 'ULTY', shares: 473.0577 },
  { T: 'CONY', shares: 71.8459 },
  { T: 'MSTY', shares: 6 },
  { T: 'SMCY', shares: 5 },
  { T: 'TSLY', shares: 8 },
  { T: 'AIYY', shares: 13 },
  { T: 'MRNY', shares: 65 },
  { T: 'NVDY', shares: 4 },
  { T: 'OXLC', shares: 51.9022 },
  { T: 'YBIT', shares: 1 },
  { T: 'CRSH', shares: 17 },
  { T: 'FIAT', shares: 22 },
]

function ListTable({
  stocks,
  tickerTypes,
  title,
  swingTrades,
}: {
  stocks: ListTableStock[]
  title: string
  tickerTypes: TickerTypes
  swingTrades?: StockIndicators[] | null
}) {
  const nav = useNavigate()
  const dataSource: (StockIndicators & ListTableStock)[] = stocks.map((s) => {
    const indicators = swingTrades?.find((st) => st.T === s.T)
    return indicators ? { ...s, ...indicators } : s
  })

  const yieldeldMax = dataSource.filter((ds: ListTableStock) => ds.name.includes('YieldMax'))
  const hasYieldMax = yieldeldMax.length

  useEffect(() => {
    console.log(`Data Source changed - ${title}`)
  }, [dataSource])

  const columns: TableColumnsType<Stock & { qdy: number }> = [
    {
      title: 'Ticker',
      dataIndex: 'T',
      key: 'T',
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      render: (value) => {
        return value?.length > 48 ? `${String(value).substring(0, 48)}...` : value
      },
    },
    {
      title: 'Prospective Yearly Yield',
      dataIndex: 'yield',
      key: 'yield',
      render: (y: number) => {
        return <>{y ? Math.round(y * 10000) / 100 : 0}%</>
      },
      sorter: (a: Stock, b: Stock) => (!a.yield ? -1 : !b.yield ? 1 : a.yield > b.yield ? 1 : -1),
    },
    {
      title: 'Prospective Yield baised on Last Quarter',
      dataIndex: 'qdy',
      key: 'qdy',
      render: (qdy: number) => {
        return <>{qdy ? Math.round(qdy * 10000) / 100 : 0}%</>
      },
      sorter: (a: Stock & { qdy: number }, b: Stock & { qdy: number }) => (a.qdy > b.qdy ? 1 : -1),
    },
    {
      title: 'Close',
      dataIndex: 'c',
      key: 'c',
      sorter: (a: Stock, b: Stock) => (a.c > b.c ? 1 : -1),
    },
    {
      title: 'Ticker Type',
      dataIndex: 'tickerType',
      key: 'tickerType',
      render: (tt) => {
        const name = tickerTypes?.results?.find((t) => t.code === tt)
        return name?.description || tt
      },
    },
    {
      title: 'Rating / Buy Cycle',
      dataIndex: 'buyScore',
      key: 'buyScore',
      render: (buyScore: number, record: StockIndicators & ListTableStock) => {
        return <BuyScore title={title} record={record} />
      },
    },
    {
      title: 'Health Score',
      dataIndex: 'healthScore',
      key: 'healthScore',
      render: (healthScore: number, record: StockIndicators & ListTableStock) => {
        return <HealthScore title={title} record={record} />
      },
    },
  ]

  if (hasYieldMax) {
    columns.push({
      title: 'Record Date',
      dataIndex: 'T',
      key: 'group',
      render: (T) => {
        const group = getGroup(T)
        return findNextDivDate(group)
      },
      sorter: (a: Stock, b: Stock) => {
        const group1 = getGroup(a.T)
        const group2 = getGroup(b.T)
        const aDate = findNextDivDate(group1)
        const bDate = findNextDivDate(group2)
        if (!(aDate && bDate)) {
          return 0
        }
        return new Date(aDate) > new Date(bDate) ? 1 : -1
      },
    })
  }

  return (
    <Layout>
      <Content style={{ margin: '1rem auto', width: '80%' }}>
        <div>
          <h3>{title}</h3>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                console.log('Row Clicked', record)
                nav(`stock/${record.T}`)
              }, // click row
            }
          }}
        />
      </Content>
    </Layout>
  )
}

export function filterAndSort(stocks: ListTableStock[]) {
  return stocks
    .filter((ds: Stock & { qdy: number }) => ds.dividends?.length > 1)
    .sort((a, b) => {
      if (!a.qdy) return 1
      if (!b.qdy) return -1
      return a.qdy > b.qdy ? -1 : 1
    })
    .slice(0, 20)
}

export function DlistPage() {
  const user = useStore<User>('user')
  const dataSource = useStore<Stock[]>('dlist')
  const dlistAction = useStore<{ key: string; loading: boolean }>('dlistAction')
  const tickerTypes = useStore<TickerTypes>('tickerType')
  const dListStatus = useStore('dlist/status')
  const swingTrades = useStore<StockIndicators[]>('dlist/swing-trades')

  const stockTypes = [TickerTypeCode.CS, TickerTypeCode.PFD, TickerTypeCode.OS]
  const etfTypes = [TickerTypeCode.ETF, TickerTypeCode.ETN, TickerTypeCode.ETV, TickerTypeCode.ETS]

  const [qds, setQds] = useState<ListTableStock[]>([])
  const [mds, setMds] = useState<ListTableStock[]>([])
  const [mde, setMde] = useState<ListTableStock[]>([])
  const [nonYM, setNonYM] = useState<ListTableStock[]>([])
  const [wde, setWde] = useState<ListTableStock[]>([])
  const [risky, setRisky] = useState<ListTableStock[]>([])

  /*
  
  */

  useEffect(() => {
    if (!tickerTypes) {
      getTickerTypes()
    }
  }, [tickerTypes])

  useEffect(() => {
    if (!dListStatus) {
      getDListStatus()
    }
  }, [dListStatus])

  useEffect(() => {
    if (!dataSource && !dlistAction?.loading) {
      getDList(user)
    }
    if (dataSource) {
      const now = Date.now()

      const data = dataSource.map((s: Stock) => {
        const lastQ = (s.dividends || []).filter((dd) =>
          dd.ex_dividend_date ? new Date(dd.ex_dividend_date).getTime() > now - oneDay * 100 : false
        )
        const qdy = (lastQ.reduce((a, b) => a + (b.cash_amount || 0), 0) * 4) / s.c
        return { ...s, qdy }
      })

      const qds = data?.filter((ds: Stock) => stockTypes.includes(ds.tickerType) && ds.frequency === 4 && !troubled.includes(ds.T)) || []
      const mds = data?.filter((ds: Stock) => stockTypes.includes(ds.tickerType) && ds.frequency === 12 && !troubled.includes(ds.T)) || []
      const mde = data?.filter((ds: Stock) => etfTypes.includes(ds.tickerType) && ds.frequency === 12 && !troubled.includes(ds.T)) || []
      const nonYM = mde.filter((ds: Stock) => !ds.name.includes('YieldMax'))
      const wde = data?.filter((ds: Stock) => weekly.includes(ds.T)) || []
      const risky = data?.filter((ds: Stock) => troubled.includes(ds.T)) || []

      const quarterDividendStocks = filterAndSort(qds)
      const monthlyDividendStocks = filterAndSort(mds)
      const monthlyDividendETF = filterAndSort(mde)
      const monthlyDividendNonYM = filterAndSort(nonYM)
      const weeklyDividendETF = filterAndSort(wde)
      const highRisk = filterAndSort(risky)

      setQds(quarterDividendStocks)
      setMds(monthlyDividendStocks)
      setMde(monthlyDividendETF)
      setNonYM(monthlyDividendNonYM)
      setWde(weeklyDividendETF)
      setRisky(highRisk)
    }
  }, [dataSource])

  const now = Date.now()
  const oneDay = 24 * 60 * 60 * 1000
  const dt = new Date(now - oneDay)

  const getProspectiveAmount = (T: string, record: { T: string; shares: number }) => {
    const stock = dataSource?.find((s) => s.T === T)

    let amount = 0
    if (stock?.frequency === 12) {
      const len = stock?.dividends.length
      const total = stock?.dividends.reduce((a, b) => a + (b?.cash_amount || 0), 0)
      const avg = total / len
      amount = Math.round(avg * record.shares * 100) / 100
    } else if (stock?.frequency === 4) {
      const lastQ = (stock?.dividends || []).filter((dd) =>
        dd.ex_dividend_date ? new Date(dd.ex_dividend_date).getTime() > now - oneDay * 93 : false
      )
      const qdy = lastQ.reduce((a, b) => a + (b?.cash_amount || 0), 0) / 3
      amount = Math.round(qdy * record.shares * 100) / 100
    } else if (T === 'YMAX') {
      const dividends = (stock?.dividends || []).sort((a, b) => (a.ex_dividend_date > b.ex_dividend_date ? 1 : -1))
      const lastMonth = [
        dividends[dividends.length - 1],
        dividends[dividends.length - 2],
        dividends[dividends.length - 3],
        dividends[dividends.length - 4],
        dividends[dividends.length - 5],
        dividends[dividends.length - 6],
        dividends[dividends.length - 7],
        dividends[dividends.length - 8],
      ]
      const total = lastMonth.reduce((a, b) => a + (b?.cash_amount || 0), 0) || 0
      const avg = total / 8
      amount = Math.round(avg * 4 * (record.shares || 0) * 100) / 100
    }
    return amount
  }
  const prospectiveTotal = owned.reduce((a, b) => a + getProspectiveAmount(b.T, b), 0)
  const currentTotal = owned.reduce((a, b) => a + b.shares * (dataSource?.find((s) => s.T === b.T)?.c || 0), 0)
  const percentage = (prospectiveTotal || 0) / (currentTotal || 1)
  let compounded = currentTotal
  for (let i = 0; i < 12; i++) {
    compounded = compounded * (1 + percentage)
  }

  return (
    <Layout>
      <Header>
        <BackHome />
        <h1>
          Epic Dividend List - {dt.getFullYear()}-{dt.getMonth() + 1}-{dt.getDate()}
        </h1>
      </Header>

      <Content>
        {tickerTypes ? (
          <>
            <ListTable title="Quarterly Dividend Stocks" tickerTypes={tickerTypes} stocks={qds} swingTrades={swingTrades} />
            <ListTable title="Monthly Dividend Stocks" tickerTypes={tickerTypes} stocks={mds} swingTrades={swingTrades} />
            <ListTable title="Monthly Dividend ETF" tickerTypes={tickerTypes} stocks={mde} swingTrades={swingTrades} />
            <ListTable title="Weekly Dividend ETF" tickerTypes={tickerTypes} stocks={wde} swingTrades={swingTrades} />
            <ListTable title="Monthly Dividend ETF (Non YieldMax)" tickerTypes={tickerTypes} stocks={nonYM} swingTrades={swingTrades} />
            <hr />
            <ListTable title="High Risk" tickerTypes={tickerTypes} stocks={risky} swingTrades={swingTrades} />
          </>
        ) : null}
      </Content>

      <Content style={{ display: user?.username !== 'mardok' ? 'none' : 'block' }}>
        <Card title="Next Projected income" style={{ margin: '1rem' }}>
          <Table
            dataSource={owned}
            columns={[
              {
                title: 'Ticker',
                dataIndex: 'T',
                key: 'T',
              },
              {
                title: 'Shares',
                dataIndex: 'shares',
                key: 'shares',
              },
              {
                title: 'Projected Monthly Income',
                dataIndex: 'T',
                key: 'T',
                render: getProspectiveAmount,
              },
            ]}
            pagination={false}
          />
          <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
            <Row>
              <Col span={5}>Next Month Prespective:</Col>
              <Col span={2} style={{ textAlign: 'right' }}>
                ${prospectiveTotal}
              </Col>
            </Row>
            <Row>
              <Col span={5}>Current Value:</Col>
              <Col span={2} style={{ textAlign: 'right' }}>
                ${Math.round(currentTotal * 100) / 100}
              </Col>
            </Row>
            <Row>
              <Col span={5}>Next 12 Months compounded:</Col>
              <Col span={2} style={{ textAlign: 'right' }}>
                ${Math.round(compounded * 100) / 100}
              </Col>
            </Row>
          </div>
        </Card>
      </Content>

      <Content style={{ margin: '1rem', textAlign: 'left', display: 'block' }}>
        <p>
          <h3>FAQ's</h3>
        </p>
        <p>A few of you have asked some questions. I will attempt to answer them here.</p>
        <Card title="Why am I giving you this?" style={{ margin: '.5rem', display: 'none' }}>
          <p>
            All of you have had an impact on my life at some time. I wanted to give something back. And in 5-10 years when I have real
            money, I want my friends to have real money too. So we can go do stuff together. Like go to Yosemite for a week in the lodge. Or
            take a balloon ride to space.
          </p>
        </Card>
        <Card title="Why the dividend stratigy" style={{ margin: '.5rem' }}>
          <p>
            I like it because it is more stable than the raw market. Sure Sound Hound stock went up 300%, but its a gamble. Dividends have
            some volitility, especially if you buy high. But even if you do buy high. The dividend payments should eventually make up the
            differance. And you don't need to sell the stock to make money. Say you get your portfolio up to $10,000. If you are more
            conservitive then me, getting 2.5% dividends a month, that is $250 of income you did not have to work for. You can then roll it
            back in, or use it as suplemental income. And you still have the stock generating more dividends.
          </p>
        </Card>
        <Card title="What is a stock?" style={{ margin: '.5rem' }}>
          <p>
            A stock is a type of security that signifies ownership in a corporation and represents a claim on part of the corporation’s
            assets and earnings.
          </p>
          <p>
            There are two main types of stock: common and preferred. Common stock usually entitles the owner to vote at shareholders’
            meetings and to receive dividends. Preferred stock generally does not have voting rights, but has a higher claim on assets and
            earnings than the common shares.
          </p>
        </Card>
        <Card title="What is an ETF?" style={{ margin: '.5rem' }}>
          <p>An exchange-traded fund (ETF) is a type of security that involves a collection of securities such as stocks or bonds.</p>
          <p>
            ETFs are in many ways similar to mutual funds; however, they are listed on exchanges and ETF shares trade throughout the day
            just like ordinary stock.
          </p>
        </Card>
        <Card title="What is a Dividend?" style={{ margin: '.5rem' }}>
          <p>
            A dividend is a payment made by a corporation to its shareholders, usually as a distribution of profits. When a corporation
            earns a profit or surplus, it can re-invest it in the business, and pay a fraction of the profit as a dividend to shareholders.
          </p>
          <p>
            Dividends are typically paid in cash, but they can also be paid in additional shares of stock. If your account has a reinvest
            option, it will buy more stock with the dividend, instead of a cash payment. Dividends provide an incentive to own stock in
            stable companies even if they are not experiencing much growth.
          </p>
        </Card>
        <Card title="Buy Rating" style={{ margin: '.5rem' }}>
          <p>
            The Buy Rating is calculated from the moving averages. If the daily Moving Averages are moving up, it gets a higher score. It is
            also weighted for higher dividend yields. I am tracking the 200, 180, 100, 20, and the 9 day moving averages.
          </p>
          <p>If the equity is losing value but paying dividends, it is eating itself. It is not a good buy.</p>
          <p>If the equity is gaining value, and paying dividends, it is a great buy.</p>
        </Card>
        <Card title="What is a Yield?" style={{ margin: '.5rem' }}>
          <p>
            A dividend yield is a financial ratio that shows how much a company pays out in dividends each year relative to its stock price.
            In the absence of any capital gains, the dividend yield is the return on investment for a stock.
          </p>
          <p>Dividend yield is calculated as follows: Dividend Yield = Annual Dividends per Share / Price per Share</p>
        </Card>
        <Card title="This is all new to me. How should I get started? Do I even want to try this?" style={{ margin: '.5rem' }}>
          <div>
            <p>
              <b>Start small</b>
            </p>
            <p>
              - Get an account from a company that allows free trades. I use https://client.schwab.com/. But there are lots of them out
              there.
            </p>
            <p>
              - Try it with as little as $20. These accounts act like long term savings accounts. It takes a few days to a week to get money
              in and out.
            </p>
            <p>
              - For fastest results for a test, buy 1 or 2 shares off the Weekly ETF's lists. Give it a week or two and see what happens.
              The equity price will change up or down. But it will also pay you some money for holding it.
            </p>
          </div>
          <div>
            <p>
              <b>Long Term</b>
            </p>
            <p>
              Deversify your investments. Stock prices for these will go up and down. Companies may increase or reduce the dividend
              payments. Buy lots of different ones to stabilize your portfolio. Don't have all your eggs in one or two baskets. Yes, CONY
              looks awesome, and it is, but the price changes wildly. Add in some other stuff to keep your portfolio stable.
            </p>
            <p>
              Consistency is key. Choose a set amount and invest that much each month. Even if its only $20 a month. After a year you could
              be getting close to that $20 back in dividends.
            </p>
          </div>
        </Card>

        <Card title="What is the Declaration Date?" style={{ margin: '.5rem' }}>
          <p>
            The declaration date is the date on which a company's board of directors announces the next dividend payment. The declaration
            date is also known as the "announcement date."
          </p>
        </Card>
        <Card title="What is the Ex-Date?" style={{ margin: '.5rem' }}>
          <p>
            The ex-dividend date is the date on which all shares held will be eligible for the dividend. To get the dividend you must hold
            the stock between the ex-date and the record date.
          </p>
        </Card>
        <Card title="What is the Record Date?" style={{ margin: '.5rem' }}>
          <p>
            The record date is the cut-off date, established by a company, in order to determine which shareholders are eligible to receive
            a dividend or distribution. If you have held the stock from the ex-date till the market opening on the record date, you are
            entitiled to the dividend.
          </p>
        </Card>
        <Card title="What is the Pay Date?" style={{ margin: '.5rem' }}>
          <p>
            The pay date is the date on which the dividend will be paid to the shareholder. The pay date is the day when the dividend
            payments will actually be sent to the shareholders of a company. This is usually 1 day to a month after the record date.
          </p>
        </Card>
        <Card title="When to buy?" style={{ margin: '.5rem' }}>
          <p>
            Prices usually dip or even crash after the ex-date. People will sometimes buy shortly before the ex-date and then sell on the
            record date to get the dividend. They only hold the stock for a short time. That is very risky, and you might loose more than
            you gain if you do so. But this will often give a good window to buy in. It usually stays cheep for a week or two after the
            record date.
          </p>
        </Card>
        <Card title="Where did you get this list?" style={{ margin: '.5rem' }}>
          <p>I compiled it, or rather I wrote a script to compile it.</p>
          <p>
            A few months ago I took a 401k from an old company and rolled it into an IRA. I wanted to add some high yield dividend stocks to
            the portfolio. I asked myself, how high does the yield go? So I spent several hours looking for some. I noticed the yield would
            change daily as the price does. I figured, I am a programmer, there has got to be a better way to find these. So I wrote a
            script to do it for me. After 3 months I am getting about 5% a month.
          </p>
        </Card>
      </Content>
    </Layout>
  )
}
