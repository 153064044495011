import React, { useEffect, useState } from 'react'
import './App.css'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { MainPage } from './pages/main'
import { LoginPage } from './pages/login'
import ErrorBoundary from 'antd/es/alert/ErrorBoundary'
import { SignUpPage } from './pages/sign-up'
import { attachToStore } from './service/websocket'
import { TicTacToeLobby } from './games'
import store, { useStore } from './store/basic-store'
import { Modal, ModalFuncProps } from 'antd'
import { JsonObj, Transport, User } from './types'
import { TicTacToeGameBoard } from './games/tic-tac-toe/game-board'
import { MyGames } from './pages/my-games'
import { getMyGames } from './service/game.action'
import { ProfilePage } from './pages/profile'
import { GamesPage } from './pages/games'
import { GroupsPage } from './pages/groups'
import { GroupPage } from './pages/group'
import { DlistPage } from './pages/dlist.page'
import { DlistDetailsPage } from './pages/dlist-details.page'
import { SwingTradePage } from './pages/dlist-swing-trade'

attachToStore()
const router = createBrowserRouter([
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '/dlist',
    element: <DlistPage />,
  },
  {
    path: '/dlist/stock/:ticker',
    element: <DlistDetailsPage />,
  },
  {
    path: '/swing-trade',
    element: <SwingTradePage />,
  },
  {
    path: '/swing-trade/stock/:ticker',
    element: <DlistDetailsPage />,
  },
  {
    path: '/games',
    element: <GamesPage />,
  },
  {
    path: '/groups',
    element: <GroupsPage />,
  },
  {
    path: 'groups/:id',
    element: <GroupPage />,
  },
  {
    path: 'games/tic-tac-toe',
    element: <TicTacToeLobby />,
  },
  {
    path: 'games/tic-tac-toe/game/:id',
    element: <TicTacToeGameBoard />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/my-games',
    element: <MyGames />,
  },
  {
    path: '/profile',
    element: <ProfilePage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
  },
])

function App() {
  const myGames: { id: string }[] | null = useStore('my-games')
  const user = useStore<User>('user')

  useEffect(() => {
    if (user?.id && !myGames) {
      getMyGames()
    }
  }, [user, myGames])
  return (
    <ErrorBoundary>
      <ErrorMessage />
      <RouterProvider router={router}></RouterProvider>
    </ErrorBoundary>
  )
}

export function ErrorMessage() {
  const [modal, contextHolder] = Modal.useModal()
  const errorPayload = useStore<Transport>('error')
  const [open, setOpen] = useState(!!errorPayload)
  const [lastError, setLastError] = useState('')

  console.log('ErrorMessage errorPayload: ', errorPayload)
  const onOk = () => {
    store.set('error', null)
    setOpen(false)
  }
  useEffect(() => {
    if (errorPayload?.error && !open) {
      const { error } = errorPayload
      const errorCode = (error as JsonObj).errorCode
      const name = (error as JsonObj).name || 'Error'
      const message = (error as JsonObj).message || errorPayload.message || 'Bad Things happened'
      const config: ModalFuncProps = {
        title: 'Error',
        content: <div>{message}</div>,
        onOk,
      }
      if (lastError !== message) {
        setOpen(true)
        setLastError(message)
      } else {
        setOpen(false)
      }

      switch (errorCode) {
        case 'UNAUTHORIZED':
          config.title = name
          config.content = <div>{message}</div>
          config.onOk = () => {
            onOk()
            store.set('auth', null)
            localStorage.setItem('auth', '')
            // @ts-ignore
            window.location = '/'
          }
          break
        default:
      }

      modal.error(config)
    }
  }, [errorPayload])
  return <>{contextHolder}</>
}

export default App
