import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getDList, getSwingTrades, getTicker } from '../service/dlist.action'
import { Alert, Col, Layout, Row, Space, Table, TableColumnsType } from 'antd'
import { useStore } from '../store/basic-store'
import { Dividend, Stock, StockIndicators } from '../types'
import { Content, Header } from 'antd/es/layout/layout'
import { CaretLeftOutlined } from '@ant-design/icons'

function mapDividendType(type: 'CD' | 'SC' | 'LT' | 'ST', frequency?: number, fulfilled?: number) {
  if (type === 'CD') {
    if (frequency === 12) {
      if (fulfilled === 1) {
        return 'Yearly Dividend'
      } else {
        return 'Monthly Dividend'
      }
    } else if (frequency === 4) {
      return 'Quarterly Dividend'
    } else {
      return 'Cash Dividend'
    }
  } else if (type === 'SC') {
    return 'Special Dividend'
  } else if (type === 'LT') {
    return 'Long Term'
  } else if (type === 'ST') {
    return 'Short Term'
  } else {
    return 'Dividend'
  }
}

function formatSigNif(num: number, places = 2) {
  let times = '1'
  for (let i = 0; i < places; i++) {
    times += '0'
  }
  const timesNum = parseInt(times, 10)
  return Math.ceil(num * timesNum) / timesNum
}

function DividendRow({ title, value }: { title: string; value: string }) {
  return (
    <Row style={{ marginBottom: '1rem', borderBottom: '1px solid black' }}>
      <Col span={20} style={{ fontWeight: 'bold' }}>
        <div>{title}</div>
      </Col>
      <Col span={4}>{value}</Col>
    </Row>
  )
}

function RatingRow({ title, value }: { title: string; value?: boolean }) {
  return (
    <Row style={{ marginBottom: '1rem', borderBottom: '1px solid black' }}>
      <Col span={20} style={{ fontWeight: 'bold' }}>
        <div>{title}</div>
      </Col>
      <Col span={4} style={{ background: value ? 'green' : 'red', textAlign: 'center' }}>
        {value ? 'YES' : 'NO'}
      </Col>
    </Row>
  )
}

export function DlistDetailsPage() {
  const nav = useNavigate()
  const { ticker } = useParams()
  const navigate = useNavigate()
  const dataSource = useStore<Stock[]>('dlist')
  const swingTrades = useStore<StockIndicators[]>('dlist/swing-trades')
  const [stock, setStock] = useState<Stock>()
  const [stockIndicator, setStockIndicator] = useState<StockIndicators>()
  const [price, setPrice] = useState(0)
  const [dividends, setDividends] = useState<Dividend[]>()
  const [yearlyDist, setYearlyDist] = useState(0)
  const [prospective, setProspective] = useState(0)
  const [showPayoutWarning, setShowPayoutWarning] = useState(false)

  useEffect(() => {
    if (ticker && stock) {
      const swing = swingTrades?.find((s) => s.T === ticker)
      if (!swing) {
        getSwingTrades([ticker])
      } else {
        setStockIndicator(swing)
        console.log('Swing: ', swing)
      }
    }
  }, [ticker, stock, swingTrades])

  const fulfilled = stock?.dividends?.length
  useEffect(() => {
    if (!ticker) {
      nav('/dlist')
    } else {
      getTicker(ticker)
    }
  }, [ticker])

  useEffect(() => {
    if (dataSource?.length) {
      const now = new Date()

      const stock = dataSource?.find((s) => s.T === ticker)
      setStock(stock)

      const price = stock?.c || 0
      setPrice(price)

      const dividends = (stock?.dividends || []).filter((div) => {
        const date = div.record_date || div.pay_date || div.ex_dividend_date
        return date && new Date(date) > now ? false : true
      })
      setDividends(dividends)

      const yearlyDist = dividends.reduce((rtn, div) => {
        return (rtn += div.cash_amount)
      }, 0)
      setYearlyDist(yearlyDist)

      const last3Months = dividends.filter((div) => {
        const date = div.record_date || div.pay_date || div.ex_dividend_date
        return date && new Date(date).getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 103 ? true : false
      })
      console.log('Last3: ', last3Months.length)
      const threeMonthTotal = last3Months.reduce((rtn, div) => (rtn += div.cash_amount), 0)

      const prospective = (threeMonthTotal * 4) / price
      setProspective(prospective)

      setShowPayoutWarning((stock?.frequency || 0) > dividends.length)
    } else {
      getDList()
    }
  }, [dataSource])

  const avgMonthly = yearlyDist / 12
  const yearlyYield = yearlyDist / price

  // Quarter yield
  // Last 3 months yield * 4 / price
  const columns: TableColumnsType<Dividend> = [
    {
      title: 'Ex Date',
      dataIndex: 'ex_dividend_date',
      key: 'ex_dividend_date',
    },
    {
      title: 'Record Date',
      dataIndex: 'record_date',
      key: 'record_date',
    },
    {
      title: 'Pay Date',
      dataIndex: 'pay_date',
      key: 'pay_date',
    },
    {
      title: 'Income',
      dataIndex: 'cash_amount',
      key: 'cash_amount',
    },
    {
      title: 'Dividend Type',
      dataIndex: 'dividend_type',
      key: 'dividend_type',
      render(value, record) {
        return mapDividendType(value, record.frequency, fulfilled)
      },
    },
  ]
  return (
    <Layout>
      <Header style={{ position: 'relative', display: 'block' }}>
        <div
          style={{ position: 'absolute', top: 'calc(50% - 30px)', left: '30px' }}
          onClick={() => {
            navigate('/dlist')
          }}
        >
          <CaretLeftOutlined style={{ fontSize: 30 }} />
        </div>
        <h1>{stock?.T}</h1>
        <h4>{stock?.name}</h4>
      </Header>
      <Content style={{ padding: '1rem', textAlign: 'left', maxWidth: '550px' }}>
        <DividendRow title="Dividend frequency per year" value={`${stock?.frequency || 'IRREGULAR'}`} />
        <DividendRow title="Total yearly distribution" value={`$${formatSigNif(yearlyDist)}`} />
        <DividendRow title="Average monthly distribution" value={`$${formatSigNif(avgMonthly)}`} />
        <DividendRow title="Stock Price (Yesterdays close)" value={`$${formatSigNif(price)}`} />
        <DividendRow title="Yield from last 12 months" value={`${Math.ceil(yearlyYield * 10000) / 100}%`} />
        <DividendRow title="Prospective yield biased on last quarter" value={`${formatSigNif((prospective * 10000) / 100)}%`} />
      </Content>
      <Space size={12} />

      {stockIndicator ? (
        <>
          <Content style={{ padding: '1rem', textAlign: 'left', maxWidth: '550px' }}>
            <h4>Buy Rating</h4>
            <h5>Technical Indicators: The more green the better.</h5>
            <RatingRow title="MA(200) Rising" value={stockIndicator?.rising200} />
            <RatingRow
              title="Closed above MA(200)"
              value={!!(stockIndicator?.c && stockIndicator?.ma200 && stockIndicator?.c > stockIndicator?.ma200)}
            />
            <RatingRow title="MA(100) Rising" value={stockIndicator?.rising100} />
            <RatingRow
              title="Closed above MA(100)"
              value={!!(stockIndicator?.c && stockIndicator?.ma100 && stockIndicator?.c > stockIndicator?.ma100)}
            />
            <RatingRow title="MA(20) Rising" value={stockIndicator?.rising20} />
            <RatingRow
              title="Closed above MA(20)"
              value={!!(stockIndicator?.c && stockIndicator?.ma20 && stockIndicator?.c > stockIndicator?.ma20)}
            />
            <RatingRow title="MA(9) Rising" value={stockIndicator?.nineRising} />
            <RatingRow
              title="Closed above MA(9)"
              value={!!(stockIndicator?.c && stockIndicator?.ma9 && stockIndicator?.c > stockIndicator?.ma9)}
            />
            <RatingRow title="Possible Reversial" value={stockIndicator?.reversial} />
            <RatingRow title="Bullish Day" value={stockIndicator?.bullDay} />
            <RatingRow title="High Dividend" value={yearlyYield > 0.5} />
          </Content>
          <Content style={{ padding: '1rem', textAlign: 'left', maxWidth: '550px' }}>
            <h4>Assessments</h4>
            <ul>
              <li style={{ listStyleType: 'circle' }}>
                {stockIndicator?.rising200
                  ? 'The 200 day Moving Average(MA) is rising. The long term prospects of this equity are good.'
                  : 'The 200 day Moving Average(MA) is falling. The long term prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {!!(stockIndicator?.c && stockIndicator?.ma200 && stockIndicator?.c > stockIndicator?.ma200)
                  ? 'Yesterdays close was above the MA(200). The long term prospects of this equity are good.'
                  : 'Yesterdays close was below the MA(200). The long term prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {stockIndicator?.rising100
                  ? 'The 100 day Moving Average(MA) is rising. The mid term prospects of this equity are good.'
                  : 'The 100 day Moving Average(MA) is falling. The mid term prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {!!(stockIndicator?.c && stockIndicator?.ma100 && stockIndicator?.c > stockIndicator?.ma100)
                  ? 'Yesterdays close was above the MA(100). The mid term prospects of this equity are good.'
                  : 'Yesterdays close was below the MA(100). The mid term prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {stockIndicator?.rising20
                  ? 'The 20 day Moving Average(MA) is rising. The short term prospects of this equity are good.'
                  : 'The 20 day Moving Average(MA) is falling. The short term prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {!!(stockIndicator?.c && stockIndicator?.ma20 && stockIndicator?.c > stockIndicator?.ma20)
                  ? 'Yesterdays close was above the MA(20). The short term prospects of this equity are good.'
                  : 'Yesterdays close was below the MA(20). The short term prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {stockIndicator?.nineRising
                  ? 'The 9 day Moving Average(MA) is rising. The daily prospects of this equity are good.'
                  : 'The 9 day Moving Average(MA) is falling. The daily prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {!!(stockIndicator?.c && stockIndicator?.ma9 && stockIndicator?.c > stockIndicator?.ma9)
                  ? 'Yesterdays close was above the MA(9). The daily prospects of this equity are good.'
                  : 'Yesterdays close was below the MA(9). The daily prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                {stockIndicator?.reversial
                  ? 'The equity is showing signs of a possible reversal. The prospects of this equity might be good.'
                  : 'The equity is not showing signs of a possible reversal. The prospects of this equity are not good.'}
              </li>
              <li style={{ listStyleType: 'circle' }}>
                Yearly income from dividends is{' '}
                {yearlyYield > 1 ? 'Epic' : yearlyYield > 0.5 ? 'Very High' : yearlyYield > 0.2 ? 'High' : 'Pretty Good'}
              </li>
            </ul>
          </Content>
        </>
      ) : null}

      <Content style={{ margin: '1rem' }}>
        {showPayoutWarning ? (
          <Alert
            message="Warning: Dividends don't match the frequency. Dividend payments have changed or may be suspended."
            type="warning"
            showIcon
            closable
          />
        ) : null}
      </Content>

      <Content style={{ margin: '1rem' }}>
        <Space size={12} />
        <Table
          columns={columns}
          dataSource={dividends?.sort((a, b) => {
            const aDate = new Date(a.ex_dividend_date || a.record_date || a.pay_date)
            const bDate = new Date(b.ex_dividend_date || b.record_date || b.pay_date)
            return aDate.getTime() > bDate.getTime() ? -1 : 1
          })}
          pagination={{ pageSize: 24 }}
        />
      </Content>
    </Layout>
  )
}
/**
 * TODO
 * Graph
 *    cache_amount
 *    date
 *    dividend type
 * record date
 * pay date
 * ex dividend date
 */
