import {
  MessageStreamStore,
  ServicePayload,
  MyGroup,
  TransportAction,
  TransportSubAction,
  AuthBody,
  GameStore,
  ZoneStore,
  Transport,
  JsonObj,
  ZoneId,
  StockIndicators,
} from '../types'
import store from '../store/basic-store'

let webSocket: WebSocket

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export async function getWebSocket(retry = 0): Promise<WebSocket> {
  if (!process.env.REACT_APP_SERVER_WS) {
    throw new Error('Invalid server')
  }
  if (webSocket && webSocket.readyState === webSocket.OPEN) {
    return webSocket
  }
  return new Promise((resolve, reject) => {
    if (!webSocket || webSocket.readyState === webSocket.CLOSING || webSocket.readyState === webSocket.CLOSED) {
      webSocket = new WebSocket(process.env.REACT_APP_SERVER_WS as string)

      wait(100).then(() => {
        getWebSocket(retry + 1)
          .then((data) => {
            attachToStore()
            resolve(data)
          })
          .catch(reject)
      })
    }
    // else if (webSocket?.readyState === webSocket?.CONNECTING) {
    if (retry < 10) {
      wait(100 * (retry + 1)).then(() => {
        getWebSocket(retry + 1)
          .then(resolve)
          .catch(reject)
      })
    } else {
      const error = new Error(`Server is down. Unable to connect.`)
      console.error(error)
      // If dev
      if (process.env.REACT_ENV === 'UAT') {
        console.warn('error ws: ', process.env.REACT_APP_SERVER_WS)
      }
      if (store) {
        store.setApi({ key: 'error', error })
      }
      reject(error)
    }
  })
}

// TODO Maybe pass in the handler
export async function attachToStore() {
  const ws = await getWebSocket()
  if (!ws.onmessage) {
    ws.onmessage = (ev: MessageEvent<any>): any => {
      handleStore(ev)
    }
    // Get auth from local storage
    const auth = localStorage.getItem('auth')
    const storeAuth = store.get('auth')
    if (auth) {
      try {
        const payload = JSON.parse(auth)
        await ws.send(
          JSON.stringify({
            action: TransportAction.AUTH,
            subAction: TransportSubAction.REFRESH,
            payload,
          })
        )
        if (!storeAuth) {
          store.setApi({ key: 'auth', data: payload })
        }
      } catch (err) {
        console.warn('Unable to reinitialize auth')
        localStorage.setItem('auth', '')
      }
    } else {
      console.info('No Auth')
    }
  }
}

export function handleStore(messageEvent: MessageEvent<string>) {
  console.log('handleStore ====>')
  try {
    const { data } = messageEvent
    const response: Transport = JSON.parse(data)
    const { error, payload, message, action, subAction } = response
    if (message) {
      console.log('ev message: ', message)
    }
    console.log('action: ', action)
    console.log('payload: ', payload)
    switch (action) {
      case TransportAction.AUTH:
        if (error) {
          store.setApi({ key: 'auth', error })
        } else {
          localStorage.setItem('auth', JSON.stringify(payload))
          store.setApi({ key: 'auth', data: payload })
          if (payload?.user) {
            store.set('user', payload.user)
          }
        }
        break
      case TransportAction.ZONE:
        handleZoneAction(payload as ZoneStore)
        break

      case TransportAction.USER:
        handleUserAction(subAction as TransportSubAction, payload as any)
        break
      case TransportAction.ROOM:
        handleGameAction(payload as GameStore)
        break
      case TransportAction.MESSAGE:
        store.set(`messages.ids_${(payload as MessageStreamStore).ids.sort().join('-')}`, payload)
        break
      case TransportAction.PUBLIC:
        handlePublicAction(subAction as TransportSubAction, payload as any)
        break

      case TransportAction.ERROR:
        console.log('ERROR: ', error)
        const statusCode = (error as JsonObj)?.statusCode || `Unknown Error`
        if (statusCode === 401) {
          store.setApi({ key: 'error', error })
        }
        // else TODO
        break
      case TransportAction.GROUPS:
        store.set('myGroups', payload)
        break
      case TransportAction.GROUP:
        const group = payload as MyGroup
        if (group.id) {
          store.set(`group_${group.id}`, payload)
        }

        break
      default:
        console.log('Unhandled Message data: ', data, action, subAction)
        break
    }
  } catch (err) {
    console.warn('handleStore Error: ', err)
  }
}

function handleUserAction(
  subAction: TransportSubAction,
  payload: {
    resource: string
    games?: { id: string; type: ZoneId }[]
    groups: MyGroup[]
    scores: { [key: string]: number }
    user: JsonObj
  }
) {
  switch (subAction) {
    case TransportSubAction.SEARCH:
      store.set('userSearch', payload)
      break
    case TransportSubAction.GET:
      switch (payload?.resource) {
        case 'games':
          console.log('payload?.games: ', payload?.games)
          store.set('my-games', payload?.games || [])
          break
        case 'groups':
          store.set('myGroups', payload?.groups || [])
          break
        case 'scores':
          store.set('scores', payload?.scores || {})
          break
        default:
          store.set('user', payload?.user || {})
          break
      }
      break
  }
}

function handleZoneAction(payload: ZoneStore) {
  const { lobby, users } = payload
  store.set('user-list', users)
  store.set(`${lobby.gameId}-lobby`, lobby)
}

function handleGameAction(payload: GameStore) {
  store.set(`game-${payload.id}`, payload)
}

export async function wsSend<T>(action: Transport<T>) {
  const ws = await getWebSocket()
  const authPayload = store.get<ServicePayload<AuthBody>>('auth')
  if (authPayload?.data?.token) {
    action.auth = authPayload?.data?.token
  }
  return ws.send(JSON.stringify(action))
}

function handlePublicAction(subAction: TransportSubAction, payload: any) {
  const { resource = 'public' } = payload
  store.setApi({ key: `${resource}Action`, loading: false })
  switch (subAction) {
    case TransportSubAction.GET:
      console.log('payload?.data: ', payload?.data?.length)
      const returned = payload?.data || []

      switch (resource) {
        case 'dlist/swing-trades':
          const stored = store.get<StockIndicators[]>(resource)
          if (!stored) {
            store.set(resource, returned)
          } else {
            // Add to array
            const storing: StockIndicators[] = []
            returned.forEach((item: StockIndicators) => {
              const found = stored.find((storedItem) => storedItem.T === item.T)
              if (!found) {
                storing.push(item)
              }
            })
            store.set(resource, [...stored, ...storing])
          }
          break
        default:
          store.set(resource, returned)
          break
      }

      // store.set(resource, payload?.data || [])
      // payload?.data?.forEach((item: any) => {
      //   // console.log('yield: ', item.yield)
      //   if (item.yield > 0.3) {
      //     console.log('yield: ', item.yield)
      //     console.log('item: ', item)
      //   }
      // })
      break
  }
}
