import store from '../store/basic-store'
import { wsSend } from './websocket'
import { TransportAction, TransportSubAction, User } from '../types'

export async function getDList(user?: User | null): Promise<void> {
  console.log('Send getDList Action')
  try {
    store.setApi({ key: 'dlistAction', loading: true })

    await wsSend({
      action: user ? TransportAction.USER : TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource: 'dlist',
      },
    })
  } catch (err) {
    console.log('Err: ', err)
    store.setApi({ key: 'dlistAction', error: (err as Error).message })
    throw new Error('Invalid Request for dlist')
  }
}

export async function getTickerTypes(): Promise<void> {
  console.log('tickerTypesAction ===>')
  store.setApi({ key: 'tickerTypesAction', loading: true })

  try {
    await wsSend({
      action: TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource: 'tickerTypes',
      },
    })
  } catch (err) {
    store.setApi({ key: 'tickerTypesAction', error: (err as Error).message })
    throw new Error('Invalid Request for tickerTypes')
  }
}

export async function getTicker(T: string) {
  console.log('getTickerAction ===>')
  const resource = 'dlist/indicators'
  store.setApi({ key: `${resource}Action`, loading: true })

  try {
    await wsSend({
      action: TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource,
        T,
        days: 30,
      },
    })
  } catch (err) {
    store.setApi({ key: `${resource}Action`, error: (err as Error).message })
    throw new Error('Invalid Request for getTickerAction')
  }
}

export async function getDListStatus() {
  console.log('getDListStatus ===>')
  const resource = 'dlist/status'
  const key = `${resource}Action`
  store.setApi({ key, loading: true })

  try {
    await wsSend({
      action: TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource,
      },
    })
  } catch (err) {
    store.setApi({ key, error: (err as Error).message })
    throw new Error('Invalid Request for getDListStatus')
  }
}

export async function getSwingTrades(tickers: string[]) {
  console.log('getSwingTrades ===>')
  const resource = 'dlist/swing-trades'
  const key = `${resource}Action`
  store.setApi({ key, loading: true })

  try {
    await wsSend({
      action: TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        resource,
        tickers,
      },
    })
  } catch (err) {
    store.setApi({ key, error: (err as Error).message })
    throw new Error('Invalid Request for getSwingTrades')
  }
}

export async function getTickerSerach(params: { volume?: number; tickers?: string[]; minPrice?: number; maxPrice?: number }) {
  console.log('getTickerSerach ===>')
  const resource = 'dlist/tickers'
  const key = `${resource}Action`
  store.setApi({ key, loading: true })

  try {
    await wsSend({
      action: TransportAction.PUBLIC,
      subAction: TransportSubAction.GET,
      payload: {
        ...params,
        resource,
      },
    })
  } catch (err) {
    store.setApi({ key, error: (err as Error).message })
    throw new Error('Invalid Request for getTickerSerach')
  }
}
