import { Checkbox, Layout, Select, Table, TableColumnsType } from 'antd'
import { BasicProps, Content, Header } from 'antd/es/layout/layout'
import { BackHome } from '../components/back-home'
import { useStore } from '../store/basic-store'
import { BuyCycle, JsonObj, ListTableStock, Stock, StockIndicators } from '../types'
import { RefAttributes, useEffect, useState } from 'react'
import { getDList, getDListStatus, getTickerSerach } from '../service/dlist.action'
import { useNavigate } from 'react-router-dom'
import { BuyScore, HealthScore } from '../components/dlist/health'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

function sortByNumber(key: string) {
  return (a: JsonObj, b: JsonObj) => {
    if (a[key] === undefined) return -1
    if (b[key] === undefined) return 1
    return a[key] - b[key]
  }
}

const healthOptions = [
  { label: 'Very Healthy', value: 6 },
  { label: 'Healthy', value: 5 },
  { label: 'OK', value: 3 },
  { label: 'Unhealthy', value: 2 },
  { label: 'Sick', value: 1 },
]

const volumeOptions = [
  { label: '500K', value: 500000 },
  { label: '1M', value: 1000000 },
  { label: '2M', value: 2000000 },
  { label: '5M', value: 5000000 },
  { label: '10M', value: 10000000 },
  { label: '50M', value: 50000000 },
  { label: '100M', value: 100000000 },
  { label: '200M', value: 200000000 },
  { label: '500M', value: 500000000 },
  { label: '1B', value: 1000000000 },
]

function ListTable({ stocks, title }: { stocks: StockIndicators[]; title: string }) {
  const nav = useNavigate()

  useEffect(() => {
    console.log(`Data Source changed - ${title}`)
  }, [stocks])

  const columns: TableColumnsType<StockIndicators> = [
    {
      title: 'Ticker',
      dataIndex: 'T',
      key: 'T',
    },
    {
      title: 'Volume',
      dataIndex: 'v',
      key: 'volume',
      render: (value) => {
        return value
      },
    },
    {
      title: 'Close',
      dataIndex: 'c',
      key: 'c',
      sorter: (a: StockIndicators, b: StockIndicators) => (a.c > b.c ? 1 : -1),
    },
    {
      title: 'Rating / Buy Cycle',
      dataIndex: 'buyScore',
      key: 'buyScore',
      render: (buyScore: number, record: StockIndicators & ListTableStock) => {
        return <BuyScore title={title} record={record} />
      },
      sorter: sortByNumber('buyScore'),
    },
    {
      title: 'Health Score',
      dataIndex: 'healthScore',
      key: 'healthScore',
      render: (healthScore: number, record: StockIndicators & ListTableStock) => {
        return <HealthScore title={title} record={record} />
      },
      sorter: sortByNumber('healthScore'),
    },
  ]

  return (
    <Layout>
      <Content style={{ margin: '1rem auto', width: '80%' }}>
        <div>
          <h3>{title}</h3>
        </div>
        <Table
          dataSource={stocks}
          columns={columns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                console.log('Row Clicked', record)
                nav(`stock/${record.T}`)
              }, // click row
            }
          }}
        />
      </Content>
    </Layout>
  )
}

export enum BuyRating {
  'BUY_WATCH', // 1
  'BUY_NOW', // 2
  'BUY', // 3
  'HOLD', // 4
  'TAKE_PROFIT', // 5
  'SELL_WATCH', // 6
  'SELL_NOW', // 7
  'SELL', // 8
  'WATCH', // 9
}

const contentStyle: IntrinsicAttributes & BasicProps & RefAttributes<HTMLElement> = {
  display: 'inline-block',
  maxWidth: '250px',
  margin: '1rem',
  textAlign: 'left',
  verticalAlign: 'top',
}

export function CheckBoxFNG({
  title,
  onChange,
  checked,
}: {
  title: string
  onChange?: ((e: CheckboxChangeEvent) => void) | undefined
  checked?: boolean
}) {
  onChange = onChange || (() => {})
  return (
    <div>
      <Checkbox checked={checked} style={{ margin: '0.5rem' }} onChange={onChange} />
      <span>{title}</span>
    </div>
  )
}

export function SwingTradePage() {
  const [buyWatchList, setBuyWatchList] = useState<StockIndicators[]>([])
  const [volume, setVolume] = useState<number>(1000000)
  const [minPrice, setMinPrice] = useState<number>(1)
  const [maxPrice, setMaxPrice] = useState<number>(300)
  const [above180, setAbove180] = useState<boolean>(false)
  const [ma180Rising, setMa180Rising] = useState<boolean>(false)
  const [above100, setAbove100] = useState<boolean>(false)
  const [ma100Rising, setMa100Rising] = useState<boolean>(false)
  const [above20, setAbove20] = useState<boolean>(false)
  const [ma20Rising, setMa20Rising] = useState<boolean>(false)
  const [healthScore, setHealthScore] = useState<number>(5)
  // uy Cycle
  const [crossingUp, setCrossingUp] = useState<boolean>(true)
  const [crossingDown, setCrossingDown] = useState<boolean>(false)
  const [reversial, setReversial] = useState<boolean>(false)
  const [falling, setFalling] = useState<boolean>(false)
  const [rising, setRising] = useState<boolean>(false)

  const [tickers, setTickers] = useState<string[]>()

  const dataSource = useStore<Stock[]>('dlist')
  const dListStatus = useStore('dlist/status')
  // const swingTrades = useStore<StockIndicators[]>('dlist/swing-trades')
  const tickerSearch = useStore<StockIndicators[]>('dlist/tickers')

  const now = Date.now()
  const oneDay = 24 * 60 * 60 * 1000
  const dt = new Date(now - oneDay)

  useEffect(() => {
    if (!dataSource) {
      getDList()
    }
    if (!dListStatus) {
      getDListStatus()
    }
    if (!tickerSearch) {
      getTickers()
    }
  }, [])

  useEffect(() => {
    let filtered = tickerSearch || []
    if (above180) {
      filtered = filtered?.filter((stock) => stock.ma180 && stock.c > stock.ma180) || []
    }
    if (ma180Rising) {
      filtered = filtered?.filter((stock) => stock.rising180) || []
    }
    if (above100) {
      filtered = filtered?.filter((stock) => stock.ma100 && stock.c > stock.ma100) || []
    }
    if (ma100Rising) {
      filtered = filtered?.filter((stock) => stock.rising100) || []
    }
    if (above20) {
      filtered = filtered?.filter((stock) => stock.ma20 && stock.c > stock.ma20) || []
    }
    if (ma20Rising) {
      filtered = filtered?.filter((stock) => stock.rising20) || []
    }
    if (healthScore) {
      console.log('filtering by health score', healthScore)
      filtered = filtered?.filter((stock) => (stock.healthScore || 0) >= healthScore) || []
    }
    if (crossingUp || crossingDown || reversial || falling || rising) {
      const filteredCycles: BuyCycle[] = []
      if (crossingUp) filteredCycles.push(BuyCycle.CROSSING_UP)
      if (crossingDown) filteredCycles.push(BuyCycle.CROSSING_DOWN)
      if (reversial) filteredCycles.push(BuyCycle.REVERSIAL)
      if (falling) filteredCycles.push(BuyCycle.FALLING)
      if (rising) filteredCycles.push(BuyCycle.RISING)
      filtered = filtered?.filter((stock) => stock.buyCycle && filteredCycles.includes(stock.buyCycle)) || []
    }

    setBuyWatchList(filtered)
  }, [tickerSearch, above180, above100, above20, healthScore, crossingUp, crossingDown, reversial, falling, rising])

  const getTickers = () => {
    getTickerSerach({ volume, minPrice, maxPrice, tickers })
  }
  useEffect(() => {
    getTickers()
  }, [volume, minPrice, maxPrice, tickers])

  useEffect(() => {
    if (dataSource) {
      console.log('dataSource ===>', dataSource.length)
    }
  }, [dataSource])

  // const buyWatchList = tickerSearch

  return (
    <Layout>
      <Header>
        <BackHome />
        <h1>
          Swing Trade - {dt.getFullYear()}-{dt.getMonth() + 1}-{dt.getDate()}
        </h1>
      </Header>

      <Content>
        <Header>
          <h3>Stock Screener</h3>
        </Header>
        <div>
          <Content style={contentStyle}>
            <Header>Price and Volume</Header>
            <div style={{ display: 'inline-block', maxWidth: '250px', margin: '1rem' }}>
              <div>Volume:</div>
              <Select
                defaultValue={volume}
                style={{ width: 120 }}
                onChange={(value: number) => {
                  setVolume(value)
                }}
                options={volumeOptions}
              />
            </div>
            <div style={{ display: 'inline-block', maxWidth: '250px', margin: '1rem' }}>
              <div>Min Price</div>
              <input
                type="number"
                value={minPrice}
                onChange={(e) => {
                  setMinPrice(parseInt(e.target.value))
                }}
              />
            </div>
            <div style={{ display: 'inline-block', maxWidth: '250px', margin: '1rem' }}>
              <div>Max Price</div>
              <input
                type="number"
                value={maxPrice}
                onChange={(e) => {
                  setMaxPrice(parseInt(e.target.value))
                }}
              />
            </div>
          </Content>
          <Content style={contentStyle}>
            <Header>Stock Health</Header>
            <CheckBoxFNG
              title="Above MA(180)"
              checked={above180}
              onChange={(e) => {
                setAbove180(e.target.checked)
                setHealthScore(0)
              }}
            />
            <CheckBoxFNG
              title="MA(180) Rising"
              checked={ma180Rising}
              onChange={(e) => {
                setMa180Rising(e.target.checked)
                setHealthScore(0)
              }}
            />
            <CheckBoxFNG
              title="Above MA(100)"
              checked={above100}
              onChange={(e) => {
                setAbove100(e.target.checked)
                setHealthScore(0)
              }}
            />
            <CheckBoxFNG
              title="MA(100) Rising"
              checked={ma100Rising}
              onChange={(e) => {
                setMa100Rising(e.target.checked)
                setHealthScore(0)
              }}
            />
            <CheckBoxFNG
              title="Above MA(20)"
              checked={above20}
              onChange={(e) => {
                setAbove20(e.target.checked)
                setHealthScore(0)
              }}
            />
            <CheckBoxFNG
              title="MA(20) Rising"
              checked={ma20Rising}
              onChange={(e) => {
                setMa20Rising(e.target.checked)
                setHealthScore(0)
              }}
            />
            <div>
              <div>Min Health Score</div>
              <Select
                value={healthScore}
                style={{ width: 120 }}
                onChange={(value: number) => {
                  setAbove180(false)
                  setAbove100(false)
                  setAbove20(false)
                  setMa180Rising(false)
                  setMa100Rising(false)
                  setMa20Rising(false)
                  setHealthScore(value)
                }}
                options={healthOptions}
              />
            </div>
          </Content>
          <Content style={contentStyle}>
            <Header>Buy Cycle</Header>
            <CheckBoxFNG
              title="Crossing Up MA(9)"
              checked={crossingUp}
              onChange={(e) => {
                setCrossingUp(e.target.checked)
              }}
            />
            <CheckBoxFNG
              title="Rising"
              checked={rising}
              onChange={(e) => {
                setRising(e.target.checked)
              }}
            />
            <CheckBoxFNG
              title="Crossing Down MA(9)"
              checked={crossingDown}
              onChange={(e) => {
                setCrossingDown(e.target.checked)
              }}
            />
            <CheckBoxFNG
              title="Falling"
              checked={falling}
              onChange={(e) => {
                setFalling(e.target.checked)
              }}
            />
            <CheckBoxFNG
              title="Possible Reversial"
              checked={reversial}
              onChange={(e) => {
                setReversial(e.target.checked)
              }}
            />
          </Content>
        </div>

        <ListTable stocks={buyWatchList || []} title="Screener" />
      </Content>
    </Layout>
  )
}
